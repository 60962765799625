<template>
<header class="bg-white">
  <div class="nav-wrapper head-container container">
    <AppLogo @bar="toggle"/>
    <AppSearch />
    <AppAction />
  </div>
  <div class="head-menu d-none d-xl-block">
    <div class="nav-wrapper head-container container p-0">
      <AppNavLeft class="bg-white horizon"/>
      <AppNavRight class="horizon gray-style-ul"/>
    </div>
  </div>
  <AppNavSider :class="{active: isOpen}"/>
</header>
</template>

<script setup>
import AppLogo from '@/components/app/Logo.vue'
import AppSearch from '@/components/app/Search.vue'
import AppAction from '@/components/app/Action.vue'
import AppNavRight from '@/components/app/NavRight.vue'
import AppNavLeft from '@/components/app/NavLeft.vue'
import AppNavSider from '@/components/app/NavSider.vue'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const isOpen = computed(() => store.state.menu.sidebar.isOpen)
const toggle = (index) => {
  store.commit('setMenu',{
    key: `sidebar.isOpen`,
    value: !isOpen.value
  })
}

</script>

<style lang="scss" scoped>
header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 51;
}

.head-menu{
  background: linear-gradient(to right, #fff 80%, #E3E4E5 20%);
  font-size: 18px;
}

.nav-wrapper {
  padding: 18px 15px;
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(md){
    padding: 13px 15px;
  }
  background-color: white;
}

</style>
