import { createStore } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const store = createStore({
  state () {
    return {
      loading: true,
      category: [],
      productTypes: [
        {
          id: '84',
          name: '威士忌',
        },
        {
          id: '85',
          name: '葡萄酒',
        },
      ],
      menu: {
        header: {
          main: {
            menuIndex: -1,
            subMenuIndex: -1,
          },
          sub: {
            menuIndex: -1,
          },
        },
        sidebar: {
          isOpen: false,
          main: {
            menuIndex: -1,
            subMenuIndex: -1,
          },
          sub: {
            menuIndex: -1,
          }
        },
        search: {
          isOpen: false,
        },
      },
      breadcrumb: {
        items: [],
      },
      magazine: {},
      meta:{
        title: `《酒訊雜誌 Wine & Spirits Digest》`,
        og: {
          url: window.location.href,
          type: 'website',
          title: `《酒訊雜誌 Wine & Spirits Digest》`,
          description: '《WSD酒訊雜誌》於1990年成立，是台灣酒界唯一專業月刊雜誌，2020年啟動網路轉型計畫。主辦過「艾雷島嘉年華」、「威士忌嘉年華」、「台北葡萄酒生活節」、「日本酒主義」、「TOP 100 WINES百大葡萄酒展」、「西班牙La Mancha產區品酒會」、「葡萄牙品酒會」等百場活動。曾出版《Whisky100》、《40度以上的天堂烈酒購買指南》、《好瓶!時尚酒莊葡萄酒選購指南》、《威語錄》等叢書。',
          image: 'https://drinker.s3.ap-northeast-1.amazonaws.com/logo.jpg',
        },
        meta: [
          {
            property: 'fb:app_id',
            content: 671097574106450,
          },
          {
            name: 'description',
            content: '《WSD酒訊雜誌》於1990年成立，是台灣酒界唯一專業月刊雜誌，2020年啟動網路轉型計畫。主辦過「艾雷島嘉年華」、「威士忌嘉年華」、「台北葡萄酒生活節」、「日本酒主義」、「TOP 100 WINES百大葡萄酒展」、「西班牙La Mancha產區品酒會」、「葡萄牙品酒會」等百場活動。曾出版《Whisky100》、《40度以上的天堂烈酒購買指南》、《好瓶!時尚酒莊葡萄酒選購指南》、《威語錄》等叢書。',
          },
        ],
      },
    }
  },
  mutations,
  getters,
  actions,
})

export default store