<template>
<div class="right-toolbox">
    <template v-if="currentRoute">
    <div class="tool-item fb">
        <a :href="`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`" target="_blank">
            <i class="icons icon-fb"></i>
        </a>
    </div>
    <div class="tool-item line">
        <a :href="`https://social-plugins.line.me/lineit/share?url=${currentUrl}&test=${currentTitle}${currentUrl}`">
            <i class="icons icon-line"></i>
        </a>
    </div>
    </template>
    <div class="tool-item gototop">
        <a @click="toTop">
            <i class="icons icon-top"></i>
        </a>
    </div>
</div>
</template>

<script setup>
import { ref, computed, onUpdated } from "vue";
import { useRoute } from 'vue-router';
import { useStore } from 'vuex'
const store = useStore()

const toTop = () => {
  window.scrollTo(0, 0);
}
const route = useRoute()
const shareable = ['article', 'product']
const currentUrl = ref(window.location.href)
const currentRoute = computed(() => {
  return shareable.includes(route.name)
})
const currentTitle = computed(() => store.state.meta.title)

onUpdated(() => {
  currentUrl.value = window.location.href
})
</script>

<style lang="scss" scoped>
.right-toolbox {
  position: fixed;
  right: 10px;
  bottom: 100px;
  z-index: 99;
  .tool-item {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 8px;
    box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
    &.fb {
      // background: #A6A8AC;
      // opacity: 0.3;
    }
    &.line {
      // background: #A6A8AC;
      // opacity: 0.3;
    }
    &.gototop {
      background: #A6A8AC;
      // opacity: 0.3;
    }
    a {
      display: block;
      width: inherit;
      height: inherit;
    }
  }
}

</style>