<template>
<div class="d-flex flex-column h-100">
  <metainfo>
  </metainfo>
  <div class="overlay" :class="{active: isOpen}" @click="toggle"></div>
  <TheHeader/>
  <div :class="{ 'd-none': !loading, ' h-100': true} " >
    <div class="d-flex justify-content-center align-items-center h-100">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  </div>

  <div :class="{ 'd-none': loading }">
    <main role="main" class="flex-shrink-0">
      <div class="container main-container">
        <router-view></router-view>
      </div>
    </main>
    <TheFooter/>
  </div>
</div>
</template>

<script setup>
import TheHeader from '@/components/layout/TheHeader.vue'
import TheFooter from '@/components/layout/TheFooter.vue'
import AppBreadcrumb from '@/components/app/Breadcrumb.vue'

import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useMeta, useActiveMeta } from 'vue-meta'
const store = useStore()
const isOpen = computed(() => store.state.menu.sidebar.isOpen)
const loading = computed(() =>  store.state.loading)
const computedMeta = computed(() => store.state.meta)
onBeforeMount(()=>{
  store.dispatch('getCategory')
})
const toggle = (index) => {
  store.commit('setMenu',{
    key: `sidebar.isOpen`,
    value: !isOpen.value
  })
}
const { meta } = useMeta(computedMeta)
</script>

<style lang="scss" scoped>
main {
  padding-top: 70px;
  @include media-breakpoint-up(xl){
    padding-top: 150px;
  }
  padding-bottom: 100px;
  // overflow: scroll;
}

.overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
  opacity: 0;
  display: none;
  &.active {
    opacity: 1;
    display: block;
    z-index: 5;
  }
}
</style>
