<template>
<div class="action">
  <i class="fas fa-search fa-lg fa-fw mobile-icon-search d-block d-xl-none" @click="toggle"></i>
</div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const isOpen = computed(() => store.state.menu.search.isOpen)
const toggle = (index) => {
  store.commit('setMenu',{
    key: `search.isOpen`,
    value: !isOpen.value
  })
}
</script>

<style lang="scss" scoped>
.action {
  display: flex;
  align-items: center;
}
</style>
