import dotProp from 'dot-prop'

export default {
  setLoading(state, loading) {
    state.loading = loading
  },
  setCategory(state, category) {
    state.category = category
  },
  setProductTypes(state, products) {
    state.productTypes = products
  },
  setMagazine(state, magazine) {
    state.magazine = magazine
  },
  setBreadcrumb(state, items) {
    state.breadcrumb.items = items
  },
  setMenu(state, {key, value}) {
    dotProp.set(state.menu, key, value)
  },
  clearMenu(state) {
    state.menu = {
      header: {
        main: {
          menuIndex: -1,
          subMenuIndex: -1,
        },
        sub: {
          menuIndex: -1,
        },
      },
      sidebar: {
        isOpen: false,
        main: {
          menuIndex: -1,
          subMenuIndex: -1,
        },
        sub: {
          menuIndex: -1,
        }
      },
      search: {
        isOpen: false,
      },
    }
  },
  setMeta(state, meta) {
    state.meta = meta
  },
  // setPostList(state, ArticleLists) {
  //   state.postList = ArticleLists
  // },
  // setPostPaginations(state, Paginations) {
  //   state.postPaginations = Paginations
  // },
  // setProductList(state, ProductLists) {
  //   state.productList = ProductLists
  // },
  // setProductPaginations(state, Paginations) {
  //   state.productPaginations = Paginations
  // },
}