import '@/assets/scss/all.scss';
import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import axios from '@/plugins/axios'
import VueAxios from 'vue-axios'
import { createMetaManager, defaultConfig, plugin as metaPlugin } from 'vue-meta'
import VueLazyload from 'vue-lazyload'

const app = createApp(App);
const metaManager =  createMetaManager(false, {
...defaultConfig,
meta: { tag: 'meta', nameless: true },
});
app.use(router);
app.use(store)
app.use(VueAxios, axios)
app.use(metaManager)
app.use(metaPlugin)
app.use(VueLazyload)

app.mount('#app')
