<template>
<div :class="{active: isOpen, search: true}">
  <input type="text" v-model="keyword" placeholder="請輸入關鍵字" @keyup.enter="search">
  <div class="dropdown" @click="toggle">
      <div class="dropdown-title">{{ selected }}<i class="fas fa-chevron-down fa-xs"></i></div>
      <ul :class="{active: isDropdown}">
          <li v-for="(item, key) in options" :key="key" @click="select(key)">
              {{ item }}
          </li>
      </ul>
  </div>
  <button type="button">
    <router-link :to="searchPage">
      <i class="fas fa-search fa-lg"></i>
    </router-link>
  </button>
</div>
</template>

<script>
export default {
  data() {
    return {
      keyword: '',
      type: 'all',
      isDropdown: false,
      options: {
        all: '全部',
        wine: '商品',
        article: '文章',
      },
    }
  },
  created() {
    window.addEventListener('click', this.close)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.close)
  },
  computed: {
    searchPage() {
      return { path: `/search/${this.type}`, query: { keyword: this.keyword }}
    },
    selected(e) {
      return this.options[this.type]
    },
    isOpen(e) {
      return this.$store.state.menu.search.isOpen
    },
  },
  methods: {
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.isDropdown = false
      }
    },
    search(e) {
      this.$router.push(this.searchPage)
    },
    select(index) {
      this.type = index
    },
    toggle() {
      this.isDropdown = !this.isDropdown
    },
  }
}
</script>

<style lang="scss" scoped>
.search {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  max-width: 30%;
  width: 100%;
  z-index: 3;
  @include media-breakpoint-down(md){
    position: fixed;
    max-width: 100%;
    // right: 50%;
    background: white;
    padding: 10px;
    top: 45px;
  }
  @include media-breakpoint-down(lg){
    display: none;
    &.active {
      display: block;
    }
  }
  .dropdown {
    cursor: pointer;
    @include media-breakpoint-down(md){
      display: none;
    }
    position: absolute;
    top: 50%;
    right: 20%;
    transform: translateY(-50%);
    i {
      margin-left: 15px;
    }

    ul {
      display: none;
      background: #fff;
      border: 1px solid #6E7177;
      position: absolute;
      top: 32px;
      &.active {
        display: block;
      }
      li {
        white-space: nowrap;
        padding: 5px 20px;
        &:hover {
          background: #F3F4F5;
        }
      }
    }
  }

  button {
    background: #D80C24;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    border-radius: 4px;
    height: 40px;
    padding: 6px 12px;
    @include media-breakpoint-down(md){
      right: 10px;
      top: 10px;
    }
  }

  input {
    background: #F3F4F5;
    border-radius: 4px;
    padding: 8px 150px 8px 15px;
    position: relative;
    border: none;
    width: 100%;
  }
}
</style>