<template>
<div class="warning-box">
    <div class="warning">
        <span>禁止酒駕</span>
        <div class="warning-pic">
            <img src="@/assets/images/icon/ico-warning.svg" alt="">
        </div>
        <span>飲酒過量有害健康</span>
    </div>
</div>
</template>

<style lang="scss" scoped>

.warning-box {
  height: 10vh;
}
.warning {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 10vh;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #0C121C;
  padding: 0px 10px;
  z-index: 50;
  color: #fff;
}
.warning span {
  font-size: 48px;
  letter-spacing: 10px;
  white-space: nowrap;
  @include media-breakpoint-down(md){
    font-size: 28px;
    letter-spacing: 5px;
  }
  @include media-breakpoint-down(xs){
    font-size: 20px;
  }
}

.warning .warning-pic {
  max-width: 50px;
  margin: 0 20px;
  @include media-breakpoint-down(md){
    max-width: 30px;
    margin: 0 10px;
  }
  @include media-breakpoint-down(xs){
    max-width: 25px;
    margin: 0 5px;
  }
}

img {
  width: 100%;
}

</style>