import { CategoryService, ProductService, PostService } from "@/apis/service";
import ls from 'localstorage-slim';

export default {
  async getCategory({commit}){
    let data
    const key = 'category'
    try {
      const cache = ls.get(key);
      if (cache) {
        data = cache
      } else {
        const response = await CategoryService.query()
        data = response.data
        ls.set(key, data, { ttl: 3600 });
      }
      commit('setCategory', data.data[0].children.data)
    } catch (error) {
      console.log(error)
    }
  },

  async getMagazine({commit}){
    try {
      const { data } = await ProductService.query({
        category_id: 106,
        orderBy:'id',
        sortedBy:'desc',
        limit: 1,
      })
      commit('setMagazine', data.data[0])
    } catch (error) {
      console.log(error)
    }
  },

}